import React, { useState, useEffect } from "react";
import emailjs from 'emailjs-com';
import './styles.css';



const Contacts = () => {
    const [userData, setUserData] = useState({
    fName:'',
    lName:'',
    phone:'',
    email:'',
    county:'',
    listService:'',
    description: ''
})
    const [isCompleted, setIsCompleted] = useState(true); 
  
    useEffect(()=> {
        const isItCompleted = Object.values(userData).every(value => !!value); // variable to determine if all fields are completed
        const re = /^\+?\d{1,4}?[-.\s]?\(?\d{1,3}?\)?[-.\s]?\d{1,4}[-.\s]?\d{1,4}[-.\s]?\d{1,9}$/;
        
        const validatePhone = re.test(userData.phone) //regex to validate phone number

        if(isItCompleted && validatePhone){
            setIsCompleted(false)
        }else{
            setIsCompleted(true)
        }
    },[userData])

    const { REACT_APP_SERVICE_ID, REACT_APP_TEMPLATE_NAME, REACT_APP_PUBLIC_KEY } = process.env //env.production

    const submitValue = (e) => {
        e.preventDefault();
        const frmdetails = {
            'from_name': 'Jerome Artis',
            'from_Email': 'jerome.artis01@gmail.com',
            'first_Name': userData.fName,
            'last_Name': userData.lName,
            'user_Phone': userData.phone,
            'user_Email': userData.email,
            'user_County': userData.county,
            'user_Service': userData.listService,
            'message': userData.description
        };
    
        emailjs.send(REACT_APP_SERVICE_ID, REACT_APP_TEMPLATE_NAME, frmdetails, REACT_APP_PUBLIC_KEY)
            .then((result) => {
                
                console.log(result.text);
                result.text === "OK" ? console.log("it worked") : alert("didnt work")
                console.log(frmdetails)
            }, (error) => {
                console.log(error.text);
            });

        setUserData({fName:'',
                    lName:'',
                    phone:'',
                    email:'',
                    county:'',
                    listService:'',
                    description: ''
                })
    }


    const onFirstName = (e) => {
        setUserData(currentData => ({ ...userData, fName: e.target.value }));
        // console.log(userData)
    }

    const onLastName = (e) => {
        setUserData(currentData => ({ ...userData, lName: e.target.value }));
        // console.log(userData)
    }

    const onPhone = (e) => {
        setUserData(currentData => ({ ...userData, phone: e.target.value }));
        // console.log(userData)
    }
    const onEmail = (e) => {
        setUserData(currentData => ({ ...userData, email: e.target.value }));
        // console.log(userData)
    }
    const onCounty = (e) => {
        setUserData(currentData => ({ ...userData, county: e.target.value }));
        // console.log(userData)
    }
    const onListOfService = (e) => {
        setUserData(currentData => ({ ...userData, listService: e.target.value }));
        console.log(userData)
    }
    const onDescription = (e) => {
        setUserData(currentData => ({ ...userData, description: e.target.value }));
        // console.log(userData)
    }
    
    return (
        <div id="contacts" >
        <div >
            <h2 className="header-contacts">Contacts</h2>
            
                <div className="row">
                        <div className=" input-field col s12 m6 l6">
                            <input value={userData.fName} placeholder="First Name" onChange={onFirstName} id="first_name" type="text" className="cust-input" />
                        </div>
                        <div className="input-field col s12 m6 l6">
                            <input value={userData.lName} placeholder="Last Name" onChange={onLastName} id="last_name" type="text" className="cust-input" />

                        </div>
                        <div className="input-field col s12 m6 l6">
                            <input value={userData.phone} placeholder="Phone Number" onChange={onPhone} id="phone_number" type="text" className="cust-input" />

                        </div>
                        <div className="input-field col s12 m6 l6">
                            <input value={userData.email} placeholder="Email" onChange={onEmail} id="email" type="text" className="cust-input" />
                        </div>

                        <div className="col s12 m6 l6 ">

                            <select   value={userData.county} placeholder="County" onChange={onCounty} className="browser-default">
                                <option className="cust-opt" value="" >Select your county</option>
                                <option className="cust-opt" value="Essex">Essex</option>
                                <option className="cust-opt" value="Bergen">Bergen</option>
                                <option className="cust-opt" value="Hudson">Hudson</option>
                                <option className="cust-opt" value="Morris">Morris</option>
                                <option className="cust-opt" value="Passaic">Passaic</option>
                                <option className="cust-opt" value="Union">Union</option>
                                <option className="cust-opt" value="Other">Other</option>
                            </select>
                        </div>
                        <div className="col s12 m6 l6">

                            <select  value={userData.listService} placeholder="List of Service" onChange={onListOfService} className="browser-default" >
                                <option className="cust-opt" value="Select service">Select Service</option>
                                <option className="cust-opt" value="Notarize Documents">Notarize Documents</option>
                                <option className="cust-opt" value="Loan Signing Agent">Loan Signing Agent</option>
                                <option className="cust-opt" value="Apostille Signing Agent">Apostille Signing Agent</option>
                                <option className="cust-opt" value="Remote Online Notary">Remote/Electronic Notarization</option>
                            </select>
                        </div>
                        <div className="row">
                            <form className="col s12 m12 l12">
                                <div className="row">
                                    <div className="cust-desc col s12">
                                        <textarea value={userData.description} placeholder="Description" onChange={onDescription} id="textarea1" className="materialize-textarea desc-input "></textarea>
                                    </div>
                                </div>
                            </form>
                        </div>
                </div>
            
            <div className="col s12 m12 l12 cust-btn">
                <button disabled={isCompleted} onClick={submitValue} className="btn">Submit</button>
            </div>
        </div>
        </div>
        
    )
}


export default Contacts;