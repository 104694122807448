import React from "react";
import './styles.css';
import background from '../../assets/background.jpeg';


const Home = () => {
    
    
        return(
                
                <div className="row">
                    <div className="gfg col s12 m12 l12" >
                        <img className="background-pic" src={background} alt='Newark New Jersey' />
                        <div className="headerText">
                        <h2 className="first-txt flow-text">DIRECT SIGNINGS SERVICES</h2>
                        <p className="subHeader flow-text">DIRECT US TO YOUR SIGNING NEEDS!</p>
                        </div>
                    </div>
                </div>
                
        )
}

export default Home;