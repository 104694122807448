export const messages = {
    services : {
        notaryDescription : 'As a notary of the state of New Jersey it is important to serve your notarial needs in an efficient and professional manner. Mobile notaries travel to you and notarize an array of documents at your convenience. Select the public meeting location, whether it be your office or a local coffee shop. Here are some types of documents that are commonely notarized:',
        listofNotes : ['Power of Attorney', 'Passport Parental Consent', 'Parental Consent for Travel', 'Medical Authorization for Minors', 'Bill of Sale'],
        listofServices : ['Contracts', 'Quitclaim Deed', 'Affidavit', 'Wills', 'Living Trusts'],
        apostileDescription: 'An apostille agent works on your behalf to get documents apostilled by a public official of a state agency, usually the Secretary of State. We can also work on your behalf to get documents authenticated by foreign consulate, usually from the U.S. Department of State in Washington D.C. As your agent we can assist you throughout the process and ensure that your documents are apostilled or autheticated and returned to you. Direct Signings Services, as your agent, will assist you through the process of getting documents apostilled or authenticated by:',
        apostileList: ['Notarizing any necessary documents','Providing courier services for standard or expedited delivery of notarized documents requiring apostille or authentication from an official state and/or federal agency', 'Submitting payment for processing fees', 'Submitting appropriate request forms','Communicating with state and federal agencies on your behalf to ensure apostille or authentication of your document(s)','Ensuring that the apostilled or authenticated document(s) are in your possession'],
        loanService: 'As a certified loan signing agent with certification from National Notary Assocation, professional and efficient loan signing services are available in counties that are in a 20 mile radius of the New Jersey zip code 07103. This service is available in person or through remote online notarization.'
        
    },
    about : {
        aboutDescription : 'Direct Siginings Services is a mobile notary company that provides notarial services at a time and location that works with your schedule. Notary publics working on behalf of Direct Siginings Services are New Jersey State certified and nationally certified siging agents through the National Notary Association.  Direct Signings Services also works on your behalf as an apostille agent to make apostille certification convenient and easy for you.',
        
    }, 
    notary : {
        notaryDescription: 'Competence, Efficiency, and Effectiveness are the leading principals behind my work ethic. I ensure that my customers needs are met within a timely manner. I ensure that the process is orgainized and seamless. Trained by the leading notary association in the nation ensures that I am always on top of current laws and regulations regarding notarial siginings. I am pleased to serve your notarial needs as a trusted notary in this industry.'
    }, 
    remote : {
        remoteDescription: 'As a remote online notary, convenience is our goal. If you have electronic documents that need to be notarized this can be done for you conveniently from your location through remote online notarization. Select the date and time for your notarial needs and we will guide you through the steps for a successful notarization. Direct Signings Services can complete notarizations for use nationwide through remote online notarization.'
    }
        
}