import React from 'react'
import './styles.css'

const Apostille = ({ app }) => {
    const { apostileDescription, apostileList } = app
    return (

        <div className="apostile">
            <p className='para-text' >
                {apostileDescription}
            </p>
            <div>
                <ol>
                    { apostileList.map((e) => {
                                return (
                                    <li className="apostille-text" key={e}>{e}</li>
                                )
                            })}
                </ol>
            </div>

        </div>

    )
}

export default Apostille;
