import React from "react";
import './styles.css'
import { messages } from '../messages';

const About = () => {
    const { about } = messages;

    return (
        <div id="About" >
           
                <div className="row">
                <div className="col s12 m12 l12" style={{ padding: '40px' }}>
                    <h2 className="about-title" style={{ textAlign: 'left' }}> About</h2>
                </div>
                <div style={{ textAlign: 'left' }} className="col s12 m12 l12">
                    <p className=" about-text">{about.aboutDescription}</p>
                </div>
                </div>
            </div>
        
    )
}

export default About;