import React from 'react'
import '../../index.css'

const Notary = ({ data }) => {
    const { notaryDescription, listofNotes, listofServices} = data
    return (
            <div>
                <p className="para-text">
                {notaryDescription}
                </p>
                <div style={{display:'flex', justifyContent:'space-evenly'}}>
                    <div className="notary-list">
                        <div className="col s12 m6 l6">
                            <ul className='list-nts'>
                                {listofNotes.map((e) => {
                                    return (
                                        <li className='list-notes' key={e}>{e}</li>
                                    )
                                })}
                            </ul>
                        </div>
                        </div>
                        <div >
                            <div className='col s12 m6 l6'>
                                <ul>
                                    {listofServices.map((e) => {
                                        return (
                                            <li className="list-services" key={e}>{e}</li>
                                        )
                                    })}
                                </ul>
                            </div>
                        </div>
                        </div>
                </div>
    )
}

export default Notary