import React from 'react'
import './styles.css'

const Remote = ({ app }) => {
    const { remoteDescription  } = app;
  return (
    <div className=" remoteSign  col s12 m12 l12">
    <p className='para-text'>
        { remoteDescription }
    </p>
        </div>
  )
}

export default Remote;
