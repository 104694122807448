import React from "react";
import photo from '../../assets/photo.png';
import { messages } from '../messages';
import './style.css'

const Notary = () => {
    const { notary } = messages;
        return (
            
            <div id="notaryPublic" className="row">
                    <div className="col s12 m12 l12" style={{ textAlign:'left'}}>
                        <h2  className="notaryHeader" style={{textAlign:'left'}}>Notary Public</h2>
                        
                        <div  className="col s12 m3 l2 xl2 ">
                            <img  className="profile" src={photo} alt='notary public' />
                        </div>
                        <div className="col s12 m10 l10 xl10 ">
                        <h3 className="notary-name" >Tiffany Boney</h3>
                        </div>
                        <div className="col s12 m12 l12 " >
                        <p className="noteRepublic-text" >
                                { notary.notaryDescription }
                            </p>
                        </div>
                        
                    </div>
            </div>
           
        ) 
}

export default Notary;