import React, { useState } from "react";
import mobileNotary from '../../assets/mobileNotary.jpg';
import agent from '../../assets/signingAgent1.jpg';
import apostille from '../../assets/apostille.jpg';
import ron from '../../assets/RON.jpg';
import { messages } from '../messages';
import Notary from './Notary'
import Apostille from "./Apostille";
import IconTitle from "./IconTitle";
import LoanSign from "./LoanSign";
import Remote from "./Remote";
import '../../index.css'
import './styles.css';

const Services = () => {
    const { services } = messages;
    const { remote } = messages;
    const [DisplayNotary, setDisplayNotary] = useState(false)
    const [DisplayLoanSign, setDisplayLoanSign] = useState(false)
    const [DisplayApostille, setDisplayApostille] = useState(false)
    const [DisplayRemoteNotary, setDisplayRemoteNotary] = useState(false)
   

    const toggleDisplayNotary = () => {
        setDisplayNotary(e => !e)
        setDisplayApostille(false)
        setDisplayLoanSign(false)
        setDisplayRemoteNotary(false)
    }

    const toggleDisplayLoanSign = () => {
        setDisplayLoanSign(e => !e)
        setDisplayApostille(false)
        setDisplayNotary(false)
        setDisplayRemoteNotary(false)
    }

    const toggleDisplayApostille = () => {
        setDisplayApostille(e => !e)
        setDisplayLoanSign(false)
        setDisplayNotary(false)
        setDisplayRemoteNotary(false)
    }

    const toggleDisplayRemoteNotary = () => {
        setDisplayRemoteNotary(e => !e)
        setDisplayLoanSign(false)
        setDisplayNotary(false)
        setDisplayApostille(false)
    }

    return (
        < >
            <div id="services" className="row">

                <h2 className="service-header">Services</h2>
            </div>

            <div className="icons-obj ">

                <IconTitle title="Mobile Notary" onImageClick={toggleDisplayNotary} img={mobileNotary} />
                <IconTitle title="Apostille Agent" onImageClick={toggleDisplayApostille} img={apostille} />
                <IconTitle title="Certified Loan Signing Agent" onImageClick={toggleDisplayLoanSign} img={agent} />
                <IconTitle title="Remote Online Notary" onImageClick={toggleDisplayRemoteNotary} img={ron} />
            </div>
            
                {DisplayNotary && (<Notary data={services} />)}
                {DisplayLoanSign && (<LoanSign data={services} />)}
                {DisplayApostille && (<Apostille app={services} />)}
                {DisplayRemoteNotary && (<Remote app={remote} />)}
          
            <div className="mobile-container">
                
                    <h6 className="title-mobile">Mobile Notary</h6>
                    <Notary data={services} />
                    <h6 className="title-mobile">Certified Loan Signing Agent</h6>
                    <LoanSign data={services} />
                    <h6 className="title-mobile" id="mobile-signingAgent">Apostille Agent</h6>
                    <Apostille app={services} />
                    <h6 className="title-mobile" id="mobile-remoteOnline">Remote Online Notary</h6>
                    <Remote app={remote} />
                    
                
                

            </div>


        </>



    )
}

export default Services;