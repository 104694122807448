import React from 'react'
import '../../index.css'

const IconTitle = ({title, onImageClick, img}) => {
    return (
        <>
            <div className="icons-title">
                <h4 className="service-title">{title}</h4>
                <img className="icons " alt={title} src={img} onClick={onImageClick} />
            </div>
        </>
    )
}

export default IconTitle