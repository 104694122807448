import React from "react";
import 'materialize-css';
import { Navbar, Icon } from 'react-materialize';
import { M }from 'materialize-css';
// import Header from "./components/Header";
import Home from "./components/Home/Home";
import About from "./components/About/About";
import Notary from "./components/NotaryPublic/notaryPublic";
import Services from "./components/Services/Services";
import Contacts from "./components/Contact/contacts";
// import logo from "./assets/logo.png";
import logo1 from "./assets/logo1.png"
import { Link } from "react-scroll";


const App = () => {
  return (
    <div>
      <div className="sticky nav-wrapper">
        <Navbar
          alignLinks="right"
          brand={<img className="brand-logo logo-title" alt="mobile notary" src={logo1} />}
          id="mobile-nav"
          menuIcon={<Icon><span className="mobile-icon">☰</span></Icon>}
          options={{
            draggable: true,
            edge: 'left',
            inDuration: 250,
            onCloseEnd: null,
            onCloseStart: null,
            onOpenEnd: null,
            onOpenStart: null,
            outDuration: 200,
            preventScrolling: true
          }}
        >
          <Link id="sec-1" to="About" spy={true} smooth={true} offset={-60}  activeClass='active'>
            About
          </Link >
          <Link id="sec-2" to="notaryPublic" spy={true} smooth={true} offset={-115} activeClass='active'>
            Notary Public
          </Link>
          <Link id="sec-3" to="services" spy={true} smooth={true} offset={-140} activeClass='active'>
            Services
          </Link>
          <Link id="sec-4" to="contacts" spy={true} smooth={true} offset={-50} activeClass='active' duration={500}>
            Contacts
          </Link>
        </Navbar>


      </div>

      <div className="container">
        <Home />
      
      <About />
      <hr />
      <Notary />
      <hr />
      <Services />
      <hr />
      <Contacts />
      </div>
      <footer className="page-footer ">
        <div className="container">
          <div className="row">
            <div className="col s12 m6 l6">
              <p className="white-text">Email: tiffanyboney@directsigningsservices.com</p>
              <p className="white-text">Phone: (862)231-4841</p>
            </div>
            <div className="col s12 m12 l12">
              <p className="grey-text text-darken-4 center text-bold">Disclaimer: State licensed notaries with Direct Signings Services do not practice law and may not give or accept fees for legal advice.</p>
            </div>
          </div>
        </div>
        <div className="center footer-copyright">
          <div className="container">
            <p className="copyright">© 2023 Copyright Text</p>

          </div>
        </div>
      </footer>
    </div>
  );
}

export default App;