import React from 'react';
import  map  from '../../assets/newarkMap.png'

const LoanSign = ({data}) => {
    const { loanService } = data
  return (
    <div>
     
    <div className="loanSigning col s12 m12 l12">
    <p className='para-text left-align'> {loanService} </p>
    </div>
    <div className='col s12 m12 l12'>
      <img  src={map} alt='Newark NJ'className='map' />
      </div>
   
    </div>
  )
}

export default LoanSign;
